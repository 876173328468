<template>
   <div class="info">
    <div class="product">
      <div class="info-title" :class="[product_active ? temp : '', `${temp}_info_title`]" @click="handleChangeTab('product')">
        <h2>{{ $t("feture.prd_info") }}</h2>
        <img src="@/assets/remark/remove.png" width="24" v-if="product_active" />
        <img src="@/assets/remark/add.png" width="24" v-else />
      </div>
      <div class="info-content product-content" :class="product_active ? 'contentShow': ''">
        <product-loading :loading="product_loading" :temp="temp">
          <div v-if="parameters.length !== 0">
            <p v-for="data in parameters" :key="data.ParameterName" :class="`${temp}_parameter`">
              <span>{{ data.ParameterName }}:</span>
              <span>{{ data.Parametervalue }}</span>
            </p>
          </div>

          <p class="intro" :class="`${temp}_intro`" v-show="productBase && productBase.description">{{ productBase && productBase.description }}</p>

          <div class="exhibt" v-show="productList.length !== 0" :style="{ border: productList.length > 2 ? '1px solid #EBEBEB': '' }">
            <div v-for="data in productList" :key="data.filehash">
              <el-image
                lazy
                :src="spliceUrl(data.filehash)"
                :fit="'contain'"
                :preview-src-list="productPreviewList"
              >
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </div>
          </div>

          <div v-if="product_block && product_block.txId" class="records" :class="`${temp}_records`" @click="handleGotoChain(product_block && product_block.txId)">
            <span class="left">{{ $t('feture.bc_rd') }}</span>
            <span>{{ short(product_block && product_block.txId, 8, 12) }}</span>
          </div>
        </product-loading>
      </div>
    </div>

    <div class="trace">
      <div class="info-title"  :class="[trace_active ? temp : '', `${temp}_info_title`]" @click="handleChangeTab('trace')">
        <h2>{{ $t('feture.trace_info') }}</h2>
        <img src="@/assets/remark/remove.png" width="24" v-if="trace_active" />
        <img src="@/assets/remark/add.png" width="24" v-else />
      </div>
      <div class="trace-content info-content" :class="trace_active ? 'contentShow': ''">
        <product-loading :loading="bind_loading" :temp="temp">
          <div class="trace-cts" v-show="product_bind && product_bind.Production && product_bind.Production.length !== 0">
            <p v-for="data in product_bind && product_bind.Production" :key="data.textplain_000" :class="`${temp}_parameter`">
              <span v-if="data.textplain_000 && String(data.textplain_000).trim() !== ''">{{ data.textplain_000 }} :</span>
              <span v-if="data.textplain_001 && String(data.textplain_001).trim() !== ''">{{ data.textplain_001 }}</span>
            </p>
          </div>

          <div class="trace-cts" v-if="product_bind && product_bind.Date && product_bind.Date.length !== 0">
            <p v-for="data in product_bind && product_bind.Date" :key="data.textplain_005" :class="`${temp}_parameter`">
              <span v-if="data.textplain_005 && String(data.textplain_005).trim() !== ''">{{ data.textplain_005 }} :</span>
              <span>{{ format(data.textdate_000, 'yyyy-MM-dd') }}</span>
            </p>
          </div>

          <div v-show="reportList.length !== 0">
            <!-- <h4>{{ $t("feture.prd_rpt") }}</h4> -->
            <div
              class="report"
              v-for="data in reportList" :key="`${data.textplain_009}${Math.random()}`"
              :style="{ flexDirection: data.otherlist_004.length > 2 ? 'column' : 'row' }"
            >
              <p class="report_p">{{ data.textplain_009 || '' }}</p>
              <section :style="{ display: 'flex' }">
                <div
                  v-for="item in data.otherlist_004" :key="`${item.filehash}${Math.random()}`"
                  :style="{ border: data.otherlist_004.length > 2 ? '1px solid #EBEBEB': '', justifyContent: data.otherlist_004.length > 2 ? 'space-between' : 'space-around' }"
                >
                  <el-image
                    :src="spliceUrl(item && item.filehash)"
                    :fit="'contain'"
                    :preview-src-list="handleGetReportPreviewList(data.otherlist_004)"
                  >
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </div>
              </section>
            </div>
          </div>

          <!-- <MapComponent :locations="gps" v-if="gps.length > 0"/> -->

          <div v-if="trace_block && trace_block.txId" class="records" :class="`${temp}_records`" @click="handleGotoChain(trace_block && trace_block.txId)">
            <span class="left">{{ $t('feture.bc_rd') }}</span>
            <span>{{ short(trace_block && trace_block.txId, 8, 12) }}</span>
          </div>
        </product-loading>
      </div>
    </div>

    <div class="brand">
      <div class="info-title" :class="[brand_active ? temp : '', `${temp}_info_title`]" @click="handleChangeTab('brand')">
        <h2>{{ $t("feture.brd_info") }}</h2>
        <img src="@/assets/remark/remove.png" width="24" v-if="brand_active" />
        <img src="@/assets/remark/add.png" width="24" v-else />
      </div>
      <div class="brand-info info-content" :class="brand_active ? 'contentShow': ''">
        <product-loading :loading="bind_loading" :temp="temp">
          <video v-show="video && temp !== 'mainetti'" style="width: 100%;background-color: #4A4A4A; margin-top: 10px" controls="controls" :src="spliceUrl(video)" />

          <div class="container">
            <h2 v-show="brand_custom && brand_custom.enterprisename" :class="`${temp}_brand_title`">{{ brand_custom && brand_custom.enterprisename }}</h2>
            <p v-for="data in brand_custom && brand_custom.description" :key="data.textmultiline_000" :class="`${temp}_desc`">{{ data.textmultiline_000 }}</p>

            <div v-show="brandList.length !== 0">
              <div class="exhibt" :style="{ border: brandList.length > 2 ? '1px solid #EBEBEB': '', justifyContent: brandList.length > 2 ? 'space-between' : 'space-around' }">
                <div v-for="data in brandList" :key="`${data.filehash}${Math.random()}`">
                  <el-image
                    lazy
                    :src="spliceUrl(data && data.filehash)"
                    :fit="'contain'"
                    :preview-src-list="brandPreviewList"
                  >
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </div>
              </div>
            </div>

            <div v-show="certList.length !== 0" style="margin-top: 10px;">
              <h4>{{ $t("feture.quca_lince")}}</h4>
              <div class="exhibt" :style="{ border: certList.length > 2 ? '1px solid #EBEBEB': '', justifyContent: certList.length > 2 ? 'space-between' : 'space-around' }">
                <div v-for="data in certList" :key="data.imgSrc">
                  <el-image
                    lazy
                    :src="spliceUrl(data && data.moreimage1.filehash)"
                    :fit="'contain'"
                    :preview-src-list="certPreviewList"
                  >
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </div>
              </div>
            </div>
          </div>
        </product-loading>
      </div>
    </div>

    <div class="logistics" v-if="logisticsList.length > 0">
      <div class="info-title" :class="[logistics_active ? temp : '', `${temp}_info_title`]" @click="handleChangeTab('logistics')">
        <h2>{{ $t("feture.logistics_info") }}</h2>
        <img src="@/assets/remark/remove.png" width="24" v-if="logistics_active" />
        <img src="@/assets/remark/add.png" width="24" v-else />
      </div>
      <div class="brand-info info-content" :class="logistics_active ? 'contentShow': ''">
        <product-loading :loading="logistics_loading" :temp="temp">
          <logistics :logisticsList="logisticsList" v-if="logisticsList.length > 0" />
          <span class="no_data" v-else>{{ $t("app.no_logistics_data") }}</span>
        </product-loading>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import * as R from 'ramda';
import VeTrueApi from '@/api/vetrue';
import { shortTxid } from '@/utils/bless'
import { dateFormat } from '@/utils';

import logistics from './logistics.vue';
import ProductLoading from './productLoading.vue';

// import MapComponent from '@/feature/components/foreignMap.vue';

export default Vue.extend({
  name: 'Remark-Main',
  components: {
    // MapComponent
    logistics,
    ProductLoading
  },
  props: ['temp'],
  data: () => ({
    product_active: false, // 控制tab隐藏or显示
    trace_active: false,
    brand_active: false,
    logistics_active: false,
    vid: '',
    data: {},
    main: '', // 主背景图
    product: {},
    productBase: {},
    productList: [], // 商品列表图
    productPreviewList: [], // 商品预览图
    labels: [], // 标签
    parameters: [], // 参数
    product_loading: false,

    product_bind: {},
    bindList: [], // 赋码追溯列表图
    reportList: [], // 生产报告列表
    gps: [],
    product_block: {},
    trace_block: {},
    bind_loading: false,

    brand_custom: {},
    video: "", // 视频文件hash
    brandList: [], // 品牌故事列表图
    brandPreviewList: [], // 品牌故事预览
    certList: [], // 资质证照
    certPreviewList: [], // 资质证照预览
    linkList: [], // 外部链接
    brand_loading: false,
    createUser: '',

    logisticsList: [], // 物流信息
    logistics_loading: false,
    // temp: 'default',
  }),
  mounted() {
    const vid = this.$route.query.vid || '';
    if (!vid) {
      this.$message({ message: this.$t('feture.miss_vid'), type: "error", offset: 300, duration: 4000 , customClass: 'el-message-custom'});
      return;
    }
    this.vid = vid;
    this.getProductBindDetail();
    this.getLogistics();
  },
  methods: {
    handleGetReportPreviewList(list) {
      if (!list && list.length === 0) return [];
      return list.map((item) => this.spliceUrl(item && item.filehash));
    },
    handleChangeTab(tab) {
      if (tab === 'product') {
        this.product_active = !this.product_active;
        if (this.product_active) {
          this.getProductDetail();
        }
      } else if (tab === 'trace') {
        this.trace_active = !this.trace_active;
        if (this.trace_active) {
          this.getProductBindDetail();
        }
      } else if (tab === 'brand') {
        this.brand_active = !this.brand_active;
        if (this.brand_active) {
          this.getBrandStory();
        }
      } else if (tab === 'logistics') {
        this.logistics_active = !this.logistics_active;
        if (this.logistics_active) {
          this.getLogistics();
        }
      }
    },
    format(time, format) {
      if (!time) {
        return '';
      }
      return `${dateFormat(time, format)}`;
    },
    short(txId, before, after) {
      return shortTxid(txId, before, after);
    },
    spliceUrl(location) {
      if (location) {
        return window.Config.bless_service_url + `/v1/public/attachment/${location}`;
      }
    },
    async getProductDetail() {
      this.product_loading = true;
      try {
        // 商品数据
        const data = await VeTrueApi.getProductDetail({ vid: this.vid });
        this.data = data;
        this.main = data.data.image;

        const product = R.pathOr({},['data', 'dcp_data', 'tc_dcp_data', 'dcpDataInfo', 'dcpDataInfo'])(data);
        this.product = product;
        this.productBase = product.base;
        this.productList = product.custom && product.custom.Moreimages || [];
        this.productPreviewList = this.productList.map(item => this.spliceUrl(item.filehash));

        this.labels = product.custom && product.custom.Productlabels || [];
        this.parameters = product.custom && product.custom.Productparameters || [];

        const createUser = R.pathOr('',['data', 'create_user', 'name'])(data);
        this.createUser = createUser;

        const product_block = R.pathOr({},['data', 'dcp_data', 'tc_dcp_data', 'dcpBlockchainInfo'])(data);
        this.product_block = product_block;

        this.product_loading = false;
      } catch (error) {
        this.product_loading = false;
        this.$message({ message: this.$t('feture.failed_get_msg') + error.message, type: "error", offset: 300, duration: 4000 , customClass: 'el-message-custom'});
        return;
      }
    },
    async getProductBindDetail() {
      this.bind_loading = true;
      try {
        // 赋码追溯数据
        const bind = await VeTrueApi.getProductBindDetail({ vid: this.vid });
        const product_bind = R.pathOr({},['data','tc_dcp_data', 'dcpDataInfo', 'dcpDataInfo', 'custom'])(bind);
        this.product_bind = product_bind;
        this.gps = !R.isEmpty(product_bind.gps) && [{...product_bind.gps}];

        const trace_block = R.pathOr({},['data', 'tc_dcp_data', 'dcpBlockchainInfo'])(bind);
        this.trace_block = trace_block;
        this.$emit("confirm", trace_block);  // 往ramark传txId

        this.reportList = product_bind && product_bind.Report || [];

        const createUser = R.pathOr('',['data', 'tc_dcp_data', 'dcpBasicInfo', 'creator'])(bind);
        this.createUser = createUser;

        this.bind_loading = false;
      } catch (error) {
        this.bind_loading = false;
        this.$message({ message: this.$t('feture.failed_get_code') + error.message, type: "error", offset: 300, duration: 4000 , customClass: 'el-message-custom'});
        return;
      }
    },
    async getBrandStory() {
      this.brand_loading = true;
      try {
        // 品牌故事
        const brand = await VeTrueApi.getBrandStory({ vid: this.vid });
        const brand_custom = R.pathOr({},['data', 'tc_dcp_data', 'dcpDataInfo', 'dcpDataInfo', 'custom'])(brand);
        this.brand_custom = brand_custom;
        this.brandList = brand_custom && brand_custom.Moreimages || []; // 品牌推广图
        this.brandPreviewList = this.brandList.map(item => this.spliceUrl(item && item.filehash))

        this.certList = brand_custom && brand_custom.Qualificationcertificate1 || [];
        this.certPreviewList = this.certList.map(item => this.spliceUrl(item && item.moreimage1.filehash))

        this.video = brand_custom && brand_custom.Morevideos && brand_custom.Morevideos.filehash;
        this.linkList = brand_custom && brand_custom.Externallinks || [];

        this.brand_loading = false;
      } catch (error) {
        this.brand_loading = false;
        this.$message({ message: this.$t('feture.failed_get_brand') + error.message, type: "error", offset: 300, duration: 4000 , customClass: 'el-message-custom'});
        return;
      }
    },
    async getLogistics() {
      this.logistics_loading = true;
      try {
        // 物流数据
        const logistics = await VeTrueApi.getLogistics({ vid: this.vid });
        this.logisticsList = logistics?.data;
        this.logistics_loading = false;
      } catch (error) {
        this.logistics_loading = false;
        this.$message({ message: this.$t('feture.failed_get_code') + error.message, type: "error", offset: 300, duration: 4000 , customClass: 'el-message-custom'});
        return;
      }
    },
    handleGotoChain(txId) {
      // window.location = `https://explore-testnet.vechain.org/transactions/${txId}`;
      // window.location = `https://explore.veworld.com/transactions/${txId}`;
      this.$router.push({
        path: '/cert',
        query:{ txId, vid: this.vid, user: this.createUser },
      })
    },
  }
})
</script>

<style lang="scss" scoped>
.no_data {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin: auto;
  margin-top: 50px;
}
.map {
  min-height: 300px;
  width: 90%;
  margin: 10px auto;
  padding-top: 20px;
}
.info {
  background-color: #FFF;
}

.info-title {
  height: 60px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FFF;
  & > h2 {
    font-size: 15px;
    font-weight: 400;
  }
}

.default {
  color: #EEE;
  background-color: #000;
}

.ginseng {
  color: #EEE;
  background-color: #4EB7A6;
}

.mainetti {
  color: #EEE;
  background-color: #151515;
}

.mainetti_info_title {
  color: #FFF !important;
  background-color: #151515 !important;
  border-bottom: 1px solid #333;
}

.mainetti_parameter {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  & > span {
    &:first-child {
      color: #8F92A1;
      margin-bottom: 6px;
    }
    &:last-child {
      color: #000;
    }
  }
}

.mainetti_intro {
  background: #F1F1F1;
  border-radius: 2px;
  border: none !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #151515 !important;
}

.mainetti_records {
  background: #151515 !important;
  border-radius: 2px !important;
  font-size: 12px !important;
  border: 1px solid #151515 !important;
  & > .left {
    font-weight: 600;
    color: #CBFC00;
    background: #151515 !important;
  }
  & > span {
    padding: 10px 5px;
    color: #B5B5B5;
  }
}

.mainetti_brand_title::after {
  display: inline-block;
  position: relative;
  content: "";
  height: 24px;
  width: 24px;
  left: -15px;
  top: 7px;
  border-radius: 50%;
  margin-right: 4px;
  background: linear-gradient(270deg, #BDEB00 0%, rgba(189, 235, 0, 0) 100%);
}

.mainetti_desc {
  font-size: 14px !important;
  color: #000 !important;
  font-family: 'Manrope';
}

.info-content {
  display: none;
}

.contentShow {
  width: 100%;
  display: block;
  background-color: #FFF;
  // min-height: 300px;
}

.product-content {
  padding: 30px 15px;
  & > ul {
    padding-left: 15px !important;
    line-height: 40px;
  }
}

.intro {
  font-size: 14px;
  color: #3B3B3B;
  margin-top: 20px;
  padding: 15px;
  line-height: 24px;
  font-weight: 400;
  border: 1px solid #EBEBEB;
}

.report {
  // margin-top: 20px;
  display: flex;
  justify-content: space-between;
  overflow-x: auto;
  padding: 5px 0;
  & > section > div {
    min-width: 112px;
    margin-right: 10px;
    &:first-child {
      margin-left: 10px;
    }
  }
}

.report_p {
  max-width: 40%;
}

.exhibt {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: auto;
  padding: 5px 0;
  & > div {
    min-width: 112px;
    margin-right: 10px;
    &:first-child {
      margin-left: 10px;
    }
  }
}

.records {
  font-size: 12px;
  color: #3B3B3B;
  margin-top: 25px;
  display: flex;
  border: 1px solid #EFEFEF;
  background-color: #F6F6F6;
  & > .left {
    font-weight: 600;
    background-color: #FFF;
  }
  & > span {
    padding: 10px 5px;
  }
}

.trace-content {
  padding: 30px 15px;
}

.trace-cts {
  display: flex;
  flex-direction: column;
  & > p {
    display: flex;
    justify-content: space-between;
  }
  .left {
    font-size: 13px;
    color: #282828;
  }

  .right {
    font-size: 13px;
    color: #7F7F7F;
  }
}

.brand-info {
  background-color: #FFF;
}

.container {
  padding: 15px;
  & > h2 {
    color: #282828;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  & > p {
    font-size: 13px;
    color: #7F7F7F;
    line-height: 24px;
  }
}

.images {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  & > span {
    font-family: Futura-Heavy, Futura;
    position: absolute;
    width: 100%;
    text-align: center;
    font-size: 60px;
    text-transform: uppercase;
    color: #FFF;
    font-weight: 800;
    line-height: 80px;
    letter-spacing: 8px;
  }
}

::v-deep .el-image {
  width: 112px;
  height: 150px;
  & > img {
    width: 112px;
    height: 150px;
  }
}
</style>
