<template>
  <div class="record">
    <el-tabs v-model="activeName">
      <el-tab-pane :label="$t('feture.records')"
        name="code"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        style="width: 100%;"
      >
        <div class="chain" @click="handleGotoChain(txId)">{{ short(txId, 12, 15) }}</div>
        <div class="history">
          <p>{{ $t('feture.tag_searched') }} {{ total }} {{ $t('feture.time') }}；</p>
          <p v-if="history.length !== 0">{{ $t('feture.first_check') }}：{{ format(history[0].created) }}</p>
          <p v-if="history.length !== 0">{{ $t('feture.first_location') }}：{{ `${history[0].country}-${history[0].city}-${history[0].district}` }}</p>
        </div>
        <div class="items" ref="history" v-if="history.length !== 0">
          <div class="item" v-for="(item, index) in history" :key="item.created + Math.random()">
            <p>{{ format(item.created) }}</p>
            <span>{{ $t('feture.no') }} {{ index + 1 }} {{ $t('feture.query') }}【{{ `${item.country}-${item.city}-${item.district}` }}】</span>
          </div>
          <div class="more" @click="handleMoreHistory">{{ isMore() ? $t('feture.more') : $t('feture.no_more') }}</div>
        </div>
        <div v-else>{{ $t('feture.no_record') }}</div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import * as R from 'ramda';
import { dateFormat } from '@/utils';
import { shortTxid } from '@/utils/bless'
import VeTrueApi from '@/api/vetrue';

export default {
  name: 'record',
  components: {},
  props: ['records', 'total', 'txId', 'vid', 'createUser'],
  data() {
    return {
      activeName: 'code',
      offset: 1,
      loading: false,
      history: this.records,
    }
  },
  mounted() {

  },
  methods: {
    handleMoreHistory() {
      if (this.isMore()) {
        this.handleNextRecord();
      }
    },
    isMore() {
      return this.total > this.history.length;
    },
    isLast() {
      // 判断是否是最后一次
      const num = this.total - this.history.length;
      return num > 20 ? 20 : num;
    },
    async handleNextRecord() {
      this.loading = true;
      try {
        // 扫码记录
        const history = await VeTrueApi.getVidHistory({ vid: this.vid, offset: this.offset + 1, limit: this.isLast() });
        const next = R.pathOr([],['data', 'list'])(history);
        this.history = this.history.concat(next);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$message({ message: this.$t('feture.scan_record_failed') + error.message, type: "error", offset: 300, duration: 4000 , customClass: 'el-message-custom'});
        return;
      }
    },
    short(txId, before, after) {
      return shortTxid(txId, before, after);
    },
    handleGotoChain(txId) {
      // window.location = `https://explore.veworld.com/transactions/${txId}`;
      this.$router.push({
        path: '/cert',
        query:{ txId, vid: this.vid, user: this.createUser },
      })
    },
    format(time) {
      return dateFormat(time);
    },
  }
}
</script>

<style lang="scss" scoped>
.record {
  width: 95%;
  margin: 0 auto;
  background-color: #FFF;
}
.more {
  position: relative;
  width: 100px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  margin: 10px 0;
  margin-left: 50%;
  left: -50px;
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid deepskyblue;
  &:hover {
    color: deepskyblue;
  }
}

.chain {
  padding: 5px 0 5px 10px;
  background-color: rgba(246, 246, 246, 1);
}

.history {
  margin: 20px auto;
  background-color: #FFF !important;
}

.items {
  height: 100vw;
  overflow-y: auto;
}

.item {
  padding: 5px 0 5px 20px;
  background-color: rgba(249, 249, 249, 1);
  border-bottom: 1px solid rgba(238, 238, 238, 1);
  & > p {
    font-size: 12px;
    height: 25px;
    line-height: 25px;
    margin: 0;
    color: rgba(169, 169, 176, 1);
    position: relative;
    padding-left: 15px;
    &::before {
      position: absolute;
      left: 0;
      top: 8px;
      content: "";
      height: 10px;
      width: 10px;
      color: rgba(169, 169, 176, 1);
      border-radius: 50%;
      border: 1px solid rgba(169, 169, 176, 1);
    }
  }
  & > span {
    padding-left: 15px;
    color: rgba(40, 40, 40, 1);
  }
}

</style>
