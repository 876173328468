<template>
   <div class="footer">
    <div class="icons" :class="temp">
      <div v-for="data in linkList" :key="data.imagelinks && data.imagelinks.filehash">
        <el-image
          lazy
          :src="spliceUrl(data.imagelinks && data.imagelinks.filehash)"
          :fit="'contain'"
          style="width: 50px; height: 50px;text-align: center;"
          @click="handleGoto(data.template_000.webpagelink)"
        >
          <div slot="error" class="image-slot">
            <img src="@/assets/remark/home.png" width="30" height="30" />
          </div>
        </el-image>
        <span style="color: #FFF;">{{data.template_000.webpagename}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { shortTxid } from '@/utils/bless';

export default {
  name: 'remark-footer',
  components: {},
  props: ['linkList', 'temp'],
  data() {
    return {

    }
  },
  mounted() {

  },
  methods: {
    short(txId, before, after) {
      return shortTxid(txId, before, after);
    },
     handleGoto(link) {
      window.location.href = link;
    },
    spliceUrl(location) {
      return window.Config.bless_service_url + `/v1/public/attachment/${location}`;
    },
  }
}
</script>

<style lang="scss" scoped>
.icons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 30px 0;
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.default {
  background-color: #1B1B1B;
}

.ginseng {
  background: rgba(78,183,166,0.8);
}

</style>
