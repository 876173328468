<template>
  <div>
    <div id="mapContainer" class="map-container"></div>
    <div class="point-list" v-if="addressList.length > 0">
      <el-timeline>
        <el-timeline-item v-for="(item, index) in addressList" :key="index" :icon="item.icon" :color="item.color" :type="item.type">
          <div class="cart">
            <div>
              <h3>{{ item.name }}</h3>
              <span>{{ timeFormat(item.time) }}</span>
            </div>
            <span class="center">{{ item.address }}</span>
            <span class="chain">{{ $t('feture.bc_rd') }}:{{ short(item.chain, 15, 15) }}</span>
          </div>
        </el-timeline-item>
      </el-timeline>
    </div>
  </div>
</template>

<script>
import dot from '@/assets/icons/dot.png';
import { shortTxid } from '@/utils/bless';
import { format } from '@/utils/time';

export default{
  name:'logistics',
  props: ['logisticsList'],
  data(){
    return{
      map:{},
      addressList:[],
      logistics: this.logisticsList,
      pointList: [],
    }
  },
  mounted(){
    this.initMap();
  },
  methods:{
    short(txId, before, after) {
      return shortTxid(txId, before, after);
    },
    timeFormat(time) {
      return format(time, 'YYYY-MM-DD HH:mm:ss');
    },
    initGPS() {
      const point = [];
      this.logistics.forEach(item => {
        const onePoint = [];
        onePoint.push(item.gps?.lon);
        onePoint.push(item.gps?.lat);
        point.push(onePoint);

        const gps = item.gps;
        this.addressList.push({
          address: `${gps.country ? gps.country : ''}${gps.province ? gps.province : ''}${gps.city ? gps.city : ''}${gps.district ? gps.district : ''}${gps.street ? gps.street : ''}`,
          name: item.department_name,
          time: item.created,
          chain: item.tx_id,
        })
      });
      return point;
    },
    initMap() {
      // 创建地图实例
      const map = new AMap.Map('mapContainer', {
        zoom: 6,// 级别
        center: [116.397428, 39.90923],// 中心点坐标
        viewMode:'3D',//使用3D视图
        mapStyle: "amap://styles/whitesmoke",
      });

      AMap.plugin([
        'AMap.ToolBar',
      ], function(){
          // 在图面添加工具条控件，工具条控件集成了缩放、平移、定位等功能按钮在内的组合控件
        map.addControl(new AMap.ToolBar({
          // 简易缩放模式，默认为 false
          liteStyle: true
        }));
      });

      // 创建多个点
      const markers = this.initGPS();
      // markers.push([116.45,38.58])

      // 创建一个 Icon
      const dotIcon = new AMap.Icon({
        // 图标的取图地址
        image: dot,
        // 图标所用图片大小
        imageSize: new AMap.Size(20, 20),
        // 图标取图偏移量
        imageOffset: new AMap.Pixel(-1, 20)
      });

      // 地图上添加点标记
      markers.forEach(function(marker) {
        new AMap.Marker({
          map: map,
          icon: dotIcon,
          position: [marker[0], marker[1]],
        });
      });

      // 创建多个点之间的连线
      const polyline = new AMap.Polyline({
        path: markers,
        strokeColor: '#000',
        strokeWeight: 2,
        strokeOpacity: 0.8
      });

      // 将连线添加到地图上
      polyline.setMap(map);
    },
  }
}
</script>

<style lang="scss" scoped>
.map-container {
  min-height: 300px;
  width: 95%;
  margin: 0 auto;
  padding-top: 20px;
}

.point-list {
  margin: 30px 20px;
}

.cart {
  font-size: 12px;
  font-family: 'PingFangSC-Regular, PingFang SC';
  font-weight: 400;
  color: #3B3B3B;
}

.cart div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h3 {
  font-size: 14px;
  font-weight: 500;
  color: #282828;
}

.cart span {
  display: block;
}

.center {
  margin-top: 10px;
  margin-bottom: 5px;
}

.chain {
  color: #A9A9B0;
}

</style>
